$(document).ready(function () {
    $('#reset_button').click(function (e) {
        e.preventDefault();
        $('#search_form').find("input[type=text], input[type=number]").val("");
        $('select').not('.nullbooleanselect').val('');
        $('select.nullbooleanselect').val('unknown');
        $('#id_active').val('1');
        $("select").trigger('change');
    });

    $('#id_username').focus();

    $('#exportButtons button[data-type]').click(function(){
        const exportType = {'_export': $(this).data('type')};
        let url = window.location.href;
        url += ( url.indexOf('?') >= 0 ? '&' : '?' ) + $.param(exportType);
        window.location.href = url;
    });

    $(document).ready(function(){
        $('.select-all').click(function() {
          var c = this.checked;
          $(':checkbox').prop('checked', c);
        });
      });
});