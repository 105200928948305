import Chart from 'chart.js';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import 'dayjs/locale/es';
import 'jquery-ui-monthpicker';
import 'jquery-ui/themes/base/all.css';

import {colors} from "../config";

dayjs.locale('es');

const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];
const monthNamesShort = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
];

const getPeriod = async (date, url) => {
    try {
        const params = {
            year: date.format('YYYY'),
            month: date.format('MM'),
        };

        const data = await axios.get(url, {params});

        return await data.data;
    } catch (e) {
        console.log(e);
    }
};

const currency = new Intl.NumberFormat("es-PY", {style: "currency", currency: "PYG"});
const number = new Intl.NumberFormat("es-PY");

const buildDebtCollectorsChart = (container) => {
    const debtCollectorsCtx = container.getContext('2d');

    const debtCollectorsChart = new Chart(debtCollectorsCtx, {
        type: 'pie',
        data: {
            datasets: [],
            labels: [],
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: 'Ingresos / Cobradores',
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        return currency.format(data['datasets'][0]['data'][tooltipItem['index']]);
                    },
                },
            },
        },
    });

    debtCollectorsChart.updateChart = async function (date) {
        const debtCollectorsData = await getPeriod(date, '/api/customers/debt-collectors-month-income/');

        const data = Object.entries(debtCollectorsData).map(([_, value]) => value);
        const labels = Object.entries(debtCollectorsData).map(([debtCollector, _]) => debtCollector);
        this.data = {
            datasets: [{
                backgroundColor: colors,
                data,
            }],
            labels,
        };

        this.update();
    };

    return debtCollectorsChart;
};

const LABELS = [
    ['total', 'Total'],
    ['debt_collectors', 'Cobradores'],
    ['gateways', 'Pasarelas'],
    ['debit', 'Débitos'],
];

const buildIncomeChart = (container) => {
    const totalAmount = $('#total-amount');
    const totalQuantity = $('#total-quantity');
    const debtCollectors = $('#debt-collectors');
    const gateways = $('#gateways');
    const debits = $('#debits');
    const others = $('#others');

    const labelWithoutTotal = LABELS.filter(([k, l]) => k !== "total");
    const pieCtx = container.getContext('2d');
    const backgroundColor = colors.slice(1, labelWithoutTotal.length + 1);

    const monthIncomeChart = new Chart(pieCtx, {
        type: 'pie',
        data: {
            datasets: [],
            labels: labelWithoutTotal.map(([_, l]) => l),
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: '% Ingresos Del Mes',
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        return currency.format(data['datasets'][0]['data'][tooltipItem['index']]);
                    },
                },
            },
        },
    });

    monthIncomeChart.updateChart = async function (date) {
        const incomeData = await getPeriod(date, '/api/customers/month-income/');

        totalAmount.html(currency.format(incomeData.total));
        totalQuantity.html(number.format(incomeData.quotas_quantity));
        debtCollectors.html(currency.format(incomeData.debt_collectors));
        gateways.html(currency.format(incomeData.gateways));
        debits.html(currency.format(incomeData.debit));
        others.html(currency.format(incomeData.others));

        this.data.datasets = [{
            data: labelWithoutTotal.map(([k, _]) => incomeData[k]),
            backgroundColor,
        }];

        this.update();
    };

    return monthIncomeChart;
};

const buildIncomeRangeIncomeChart = (container) => {

    const camelCase = str => {
        return `${str[0].toUpperCase()}${str.slice(1)}`
    };

    const formatLabel = date => {
        return `${camelCase(date.format('MMM'))}/${date.format('YYYY')}`;
    };

    const config = {
        type: 'line',
        data: {
            datasets: [],
            labels: [],
        },
        options: {
            responsive: true,
            title: {
                display: true,
                text: 'Ingreso Cuotas Por Mes',
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: (value) => currency.format(value),
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Montos',
                    },
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Meses',
                    },
                }],
            },
            tooltips: {
                callbacks: {
                    label: (item) => currency.format(item.value),
                }
            }
        }
    };

    const monthRangeCtx = container.getContext('2d');
    const monthIncomeRangeChart = new Chart(monthRangeCtx, config);

    monthIncomeRangeChart.updateChart = async function (date) {
        const periods = _.range(6).reverse().map(v => date.subtract(v, 'month'));

        this.periods = periods;

        const labels = periods.map(p => formatLabel(p));

        const periodAmounts = periods.map(async d => await getPeriod(d, '/api/customers/month-income/'));

        Promise.all(periodAmounts).then(data => {
            this.data = {
                datasets: LABELS.map(([key, label], i) => (
                    {
                        backgroundColor: colors[i],
                        borderColor: colors[i],
                        label: label,
                        labelKey: key,
                        data: data.map(d => d[key]),
                        fill: false,
                    }
                )),
                labels,
            };

            this.update();
        });
    };

    monthIncomeRangeChart.addPreviousMonth = async function() {
        const previousMonth = this.periods[0].subtract(1, 'month');

        this.periods = [previousMonth, ...this.periods];

        this.data.labels = [formatLabel(previousMonth), ...this.data.labels];

        const monthData = await getPeriod(previousMonth, '/api/customers/month-income/');

        this.data.datasets = this.data.datasets.map(d => ({
            ...d,
            data: [monthData[d.labelKey], ...d.data],
        }));

        this.update();
    };

    monthIncomeRangeChart.addNextMonth = async function() {
        const nextMonth = this.periods[this.periods.length - 1].add(1, 'month');

        this.periods = [...this.periods, nextMonth];

        this.data.labels = [...this.data.labels, formatLabel(nextMonth)];

        const monthData = await getPeriod(nextMonth, '/api/customers/month-income/');

        this.data.datasets = this.data.datasets.map(d => ({
            ...d,
            data: [...d.data, monthData[d.labelKey]],
        }));

        this.update();
    };

    $('#addPreviousMonthButton').click(async () => {
        await monthIncomeRangeChart.addPreviousMonth();
    });

    $('#addNextMonthButton').click(async () => {
        await monthIncomeRangeChart.addNextMonth();
    });

    return monthIncomeRangeChart;
};

(async ($) => {

    const buildCharts = async () => {

        const chartsManager = {
            charts: [
                buildDebtCollectorsChart(debtCollectorsContainer),
                buildIncomeChart(monthIncomeContainer),
                buildIncomeRangeIncomeChart(monthRangeIncomeContainer),
            ],
            updateCharts: async function(date) {
                await Promise.all(this.charts.map(c => c.updateChart(date)));
            }
        };

        let selectedDate = dayjs();
        const monthPicker = $('.monthpicker').val(selectedDate.format("MM/YYYY"));

        const customParseFormat = require('dayjs/plugin/customParseFormat');
        dayjs.extend(customParseFormat);

        monthPicker.monthpicker({
            changeYear: true,
            onSelect: async function(param) {
                selectedDate = dayjs(`01/${param}`, 'DD/MM/YYYY');
                await chartsManager.updateCharts(selectedDate);
            },
            monthNames,
            monthNamesShort,
        });

        await chartsManager.updateCharts(selectedDate);
    };

    const monthIncomeContainer = document.getElementById('month-income-chart');
    const debtCollectorsContainer = document.getElementById('debt-collectors-chart');
    const monthRangeIncomeContainer = document.getElementById('month-range-income-chart');

    if (debtCollectorsContainer && monthIncomeContainer && monthRangeIncomeContainer) {
        await buildCharts();
    }

})(jQuery);
