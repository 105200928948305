($ => {

    $(document).ready(function () {

        $('#payment-collect').submit(() => {
            let amount = $('#id_amount').val();
            amount = Number(amount).toLocaleString('es');
            return confirm('Confirmar pago por ' + amount + ' PYG');
        });

        $('input.payment').click(function () {
            let sum = 0.0;

            $("input.payment:checked").each(function () {
                const value = $(this).attr('data-price');
                sum += parseFloat(value);
            });

            $('#id_amount').val(sum);
        });
    });
})(jQuery);