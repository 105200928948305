export const colors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(124, 255, 108, 0.6)',
    'rgba(255,14,19,0.6)',
    'rgba(18,13,255,0.6)',
    'rgba(255,244,21,0.6)',
];