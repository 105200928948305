($ => {
    function reCalculate() {
        let cash = 0.0;
        let card = 0.0;
        let value = 0;
        let method = 0;

        $("input.payment:checked").each(function () {
            value = $(this).attr('data-amount');
            method = $(this).attr('data-method');

            if (method == 'cash') {
                cash += parseFloat(value);
            } else {
                card += parseFloat(value);
            }

        });

        cash = Number(cash).toLocaleString();
        card = Number(card).toLocaleString();
        $('#total-cash').text(cash);
        $('#total-card').text(card);
        $('#total-invoices').text($("input.invoice:checked").length);
        $('#total-payments').text($("input.payment:checked").length);
    }

    $(document).ready(function () {
        let inputClass = '';
        $('input.select-all').change(function () {
            inputClass = $(this).attr('data-class');
            if(this.checked){
                $('input.' + inputClass).each(function(){
                    this.checked = true;
                });
            }else{
                 $('input.' + inputClass).each(function(){
                    this.checked = false;
                });
            }
            reCalculate();
        });

        $('input.payment').click(function () {
            reCalculate();
        });

    });

})(jQuery);
