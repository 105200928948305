require('./plugins/jquery.formset');

($ => {
    const total = {
        sequence: 0,
        elements: {},
        subtotalSpan: $('#subtotal'),
        totalSpan: $('#total'),
        next: function () {
            this.sequence += 1;
            return this.sequence;
        },
        add: function (element) {
            const el = $(element);
            const elId = this.next();
            el.attr('data-id', elId);
            const quantityInput = el.find('.quantity')[0];
            const priceUnit = el.find('.price_unit')[0];
            const onChange = () => {
                const quantity = $(quantityInput).val();
                const price = $(priceUnit).val();
                const total = (quantity * price);
                const priceTotal = el.find('.price_total')[0];
                $(priceTotal).val(total);
                this.elements[elId] = total;
                this.updateTotal();
            };
            $(quantityInput).on('change', onChange);
            $(priceUnit).on('change', onChange);
            $(priceUnit).trigger('change');
        },
        updateTotal: function () {
            const total = Number(this.getTotal()).toLocaleString('es');
            this.subtotalSpan.text(total);
            this.totalSpan.text(total);
        },
        del: function (element) {
            delete this.elements[$(element).attr('data-id')];
            this.updateTotal();
        },
        getTotal: function () {
            return Object.entries(this.elements).reduce(
                (sum, element) => {
                    return sum + element[1];
                }, 0);
        }
    };
    const selector = '#purchase_form tbody tr';
    $(selector).formset({
        addText: '<i class="fa fa-plus"></i>',
        deleteText: '<i class="fa fa-minus"></i>',
        prefix: 'orderline_set',
        added: function (e) {
            $(e).find('p.invalid-feedback').remove();
            $(e).find('.is-invalid').removeClass('is-invalid');
            $(e).find('select').val('').select2({
                language: 'es',
                width: '100%',
            });
            total.add(e);
        },
        removed: function (e) {
            total.del(e);
        }
    });

    $(selector).each(function (i, e) {
        total.add(e);
    });

    $('#submit-button').on('click', function () {
        $('#purchase_form').submit();
    });
})(jQuery);
