import Chart from 'chart.js';
import axios from 'axios';

import {colors} from "../config";

const buildCharts = (responseData) => {
    const pieCtx = pieChartContainer.getContext('2d');
    const labels = responseData.reduce((l, row) => {
        return [...l, row.username]
    }, []);
    const backgroundColor = colors.slice(0, responseData.length);

    const pieChart = new Chart(pieCtx, {
        type: 'pie',
        data: {
            datasets: [{
                data: responseData.reduce((l, row) => {
                    return [...l, row.customers];
                }, []),
                backgroundColor,
            }],
            labels,
        },
        // options: options
    });

    const barsCtx = barsChartContainer.getContext('2d');
    const barsChart = new Chart(barsCtx, {
        type: 'bar',
        data: {
            datasets: [{
                label: '% al día',
                data: responseData.reduce((l, row) => {
                    return [...l, row.up_to_date];
                }, []),
                backgroundColor,
            }],
            labels,
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        max: 100,
                        beginAtZero: true
                    }
                }]
            }
        }
    });
};

const customerStats = document.getElementById('customer-stats');
const pieChartContainer = document.getElementById('pie-chart');
const barsChartContainer = document.getElementById('bars-chart');

if (customerStats && pieChartContainer && barsChartContainer) {

    axios.get('/api/customers/collector-stats/')
        .then(response => {
            buildCharts(response.data);
        })
        .catch(error => {
            // handle error
            console.log(error);
        });

}
